import React, { MouseEventHandler } from 'react';
import { Box, Tooltip, PopoverProps } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Profile } from '../../model/profile';
import { IoRocketSharp as BoostIcon } from 'react-icons/io5';
import BoostFeatureDetails from '../../containers/team/edit/components/BoostFeatureDetails';
import axios from 'axios';
import { Boost, BoostFeature } from '../../model/boost';
import { handleError } from '../snackbar/reducer';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import { showGlobalBoostFeatureDetails } from '../../containers/app/reducer';

interface BoostGateProps {
    children: React.ReactNode;
    type: 'team' | 'user';
    featureId: string;
    requiredLevel?: number;
    requiredPropertyCount?: number | (() => Promise<number>);
    labelSx?: SystemStyleObject;
    boxSx?: SystemStyleObject;
    byPass?: boolean;
    hideLabel?: boolean;
    PopoverProps?: Omit<PopoverProps, 'open' | 'onClose'>;
    hideBackdrop?: boolean;
    useGlobalGate?: boolean;
}

const noBoosts = {};

function BoostGate({
    children,
    type,
    featureId,
    requiredLevel,
    requiredPropertyCount,
    labelSx = {},
    boxSx = {},
    byPass = false,
    hideLabel = false,
    hideBackdrop = false,
    useGlobalGate = false,
    PopoverProps = {},
}: BoostGateProps) {
    const dispatch = useDispatch();
    const profile = useSelector((state: any) => state.app.profile) as Profile | null;
    const boostFeatures = useSelector((state: any) => state.app.boostFeatures) as Record<string, BoostFeature>;
    const boostCounts = React.useMemo<Record<string, number>>(() => {
        if (type === 'team') {
            return profile?.Team?.Boosts || noBoosts;
        } else if (type === 'user') {
            return profile?.Boosts || noBoosts;
        }

        return noBoosts;
    }, [profile, type]);
    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
    const [showBoostFeature, setShowBoostFeature] = React.useState(false);
    const [isLoadingUserDetails, setLoadingUserDetails] = React.useState(false);
    const [currentOwnBoostCount, setCurrentOwnBoostCount] = React.useState(0);
    const [currentLockedBoostCount, setCurrentLockedBoostCount] = React.useState(0);
    const [availableBoostCount, setAvailableBoostCount] = React.useState(0);
    const detailsRef = React.useRef<HTMLDivElement | null>(null);
    const [resolvedRequiredCount, setResolvedRequiredCount] = React.useState<number | undefined>(undefined);

    React.useEffect(() => {
        let timeout = 0;
        async function load() {
            if (requiredPropertyCount === undefined) {
                setResolvedRequiredCount(undefined);
                return;
            }

            if (typeof requiredPropertyCount === 'number') {
                setResolvedRequiredCount(requiredPropertyCount);
                return;
            }

            try {
                const count = await requiredPropertyCount();
                setResolvedRequiredCount(count);
            } catch (e) {
                timeout = window.setTimeout(load, 5000);
            }
        }

        load();

        return () => {
            clearTimeout(timeout);
        };
    }, [requiredPropertyCount]);

    const feature = boostFeatures[featureId];
    const hasAccess = React.useMemo(() => {
        if (!feature) {
            return true;
        }
        if (resolvedRequiredCount === 0) {
            return true;
        }

        const count = boostCounts[featureId] || 0;

        let levelIndex = 0;
        for (const level of feature.Levels) {
            levelIndex++;
            if (level.TotalNumBoosts > count) {
                break;
            }

            if (requiredLevel !== undefined && requiredLevel <= levelIndex) {
                return true;
            }
            if (resolvedRequiredCount !== undefined) {
                const grantedCount = level.Properties?.count || 0;

                if (grantedCount < 0 || grantedCount >= resolvedRequiredCount) {
                    return true;
                }
            }
        }

        return false;
    }, [boostCounts, feature, requiredLevel, resolvedRequiredCount]);

    const boostedEntity = React.useMemo(() => {
        if (type === 'team') {
            const teamId = profile?.Team?.ID;
            if (teamId) {
                return {
                    teamId,
                };
            }
        }
        if (type === 'user') {
            const userId = profile?.ID;
            if (userId) {
                return {
                    userId,
                };
            }
        }

        return {};
    }, [profile, type]);

    async function loadUserDetails() {
        setLoadingUserDetails(true);
        try {
            const res = await axios.get('/api/boosts/user');
            const boosts = res.data as Array<Boost>;

            let currentCount = 0,
                lockedCount = 0,
                availableCount = 0;
            for (const boost of boosts) {
                if (boost.AppliedFeatureID === null) {
                    availableCount++;
                } else if (boost.AppliedFeatureID === featureId) {
                    if (
                        (boostedEntity.teamId && boostedEntity.teamId === boost.AppliedTeam?.ID) ||
                        (boostedEntity.userId && boostedEntity.userId === boost.AppliedUser?.ID)
                    ) {
                        currentCount++;

                        if (boost.LockedUntil) {
                            lockedCount++;
                        }
                    }
                }
            }

            setCurrentOwnBoostCount(currentCount);
            setCurrentLockedBoostCount(lockedCount);
            setAvailableBoostCount(availableCount);
        } catch (e) {
            handleError(e)(dispatch);
        }
        setLoadingUserDetails(false);
    }

    const handleClickCapture = React.useCallback<MouseEventHandler<HTMLDivElement>>(
        (e) => {
            if (!byPass && !hasAccess) {
                // @ts-ignore
                if (!detailsRef.current?.contains(e.target)) {
                    return;
                }

                e.stopPropagation();

                if (useGlobalGate) {
                    showGlobalBoostFeatureDetails(type, featureId)(dispatch);
                    return;
                }

                setAnchorEl(e.currentTarget);
                setShowBoostFeature(true);
                loadUserDetails();
            }
        },
        [hasAccess, byPass]
    );

    const handleMouseEnter = React.useCallback(async () => {
        if (typeof requiredPropertyCount === 'function') {
            const resolvedCount = await requiredPropertyCount();
            setResolvedRequiredCount(resolvedCount);
        }
    }, [requiredPropertyCount]);

    const currentBoostCount = boostCounts[featureId] || 0;

    return (
        <Box
            ref={detailsRef}
            sx={{
                position: 'relative',
                '.boost-gate-label': {
                    boxShadow: 5,
                    position: 'absolute',
                    top: -5,
                    right: -5,
                    backgroundColor: 'rgb(11,6,23)',
                    color: 'rgb(234,233,233)',
                    borderRadius: '50%',
                    width: 20,
                    height: 20,
                    padding: 0.5,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    ...labelSx,
                },
                ...boxSx,
            }}
            onClickCapture={handleClickCapture}
            onMouseEnter={handleMouseEnter}
        >
            {children}

            {anchorEl && feature && !isLoadingUserDetails && (
                <BoostFeatureDetails
                    boostedEntity={boostedEntity}
                    selectedBoost={feature}
                    selectedBoostAnchorEl={anchorEl}
                    currentBoostCount={currentBoostCount}
                    currentOwnBoostCount={currentOwnBoostCount}
                    currentLockedBoostCount={currentLockedBoostCount}
                    availableBoostCount={availableBoostCount}
                    defaultSelectedLevel={requiredLevel ? requiredLevel - 1 : undefined}
                    isOpen={showBoostFeature}
                    onClose={() => setShowBoostFeature(false)}
                    onUpdated={async () => {
                        await loadUserDetails();
                        setShowBoostFeature(false);
                    }}
                    sx={{ minWidth: 400 }}
                    PopoverProps={PopoverProps}
                    hideBackdrop={hideBackdrop}
                />
            )}

            {(!hasAccess || !hideLabel) && (
                <Tooltip title="This is a boost feature." arrow placement="top">
                    <div className="boost-gate-label">
                        <BoostIcon />
                    </div>
                </Tooltip>
            )}
        </Box>
    );
}

export default BoostGate;
