import { ReplaySubject, Subject } from 'rxjs';
import { RecordingSessionComment, RecordingSessionCommentReaction } from '../model/recorder';
import { Party } from '../model/play';

export interface AuthVerifyMessage {
    MessageType: 'auth.verify';
}

export interface RecorderViewAccessResultMessage {
    MessageType: 'recorder.ViewAccessResult';
    Payload: {
        Granted: boolean;
    };
}

export interface RecorderRecordingUpdatedMessage {
    MessageType: 'recorder.RecordingUpdated';
    Payload: {
        SessionUid: string;
    };
}

export interface RecordingSessionCommentsReactionCreated {
    MessageType: 'recording.session.comments.reaction.created';
    Payload: {
        SessionUid: string;
        CommentUid: string;
        Reaction: RecordingSessionCommentReaction;
    };
}

export interface RecordingSessionCommentsReactionDeleted {
    MessageType: 'recording.session.comments.reaction.deleted';
    Payload: {
        SessionUid: string;
        CommentUid: string;
        ReactionType: string;
        UserID: string;
    };
}

export interface RecordingSessionCommentsCreatedOrUpdated {
    MessageType: 'recording.session.comments.created' | 'recording.session.comments.updated';
    Payload: {
        SessionUid: string;
        Comment: RecordingSessionComment;
    };
}

export interface PartyUpdated {
    MessageType: 'party.Updated';
    Payload: {
        Party: Party;
    };
}

export type WebsocketMessage =
    | AuthVerifyMessage
    | RecorderViewAccessResultMessage
    | RecorderRecordingUpdatedMessage
    | RecordingSessionCommentsCreatedOrUpdated
    | RecordingSessionCommentsReactionCreated
    | RecordingSessionCommentsReactionDeleted
    | PartyUpdated;

class WebsocketManager {
    shouldReconnect = true;
    connected: boolean | null = null;
    pendingMessages: Array<string> = [];
    socket: WebSocket | null = null;
    emitter = new Subject<WebsocketMessage>();
    connectionStatus = new ReplaySubject<boolean>(1);

    constructor(private endpoint: string, private instanceId: string) {
        this.setConnected(false);
    }

    connect() {
        this.shouldReconnect = true;
        this.updateSocket();
    }

    reconnect() {
        console.log('[Websocket] Reconnect');
        this.shouldReconnect = true;
        this.socket?.close();
        this.updateSocket();
    }

    disconnect() {
        console.log('[Websocket] Disconnect');
        this.shouldReconnect = false;
        if (this.socket) {
            this.socket.close();
        }
    }

    subscribeConnectionStatus(cb: (isConnected: boolean) => void) {
        return this.connectionStatus.subscribe(cb);
    }

    subscribe(cb: (msg: WebsocketMessage) => void) {
        return this.emitter.subscribe(cb);
    }

    send(type: string, payload?: any) {
        const message = JSON.stringify({
            Type: type,
            Payload: payload,
        });

        if (!this.connected || !this.socket) {
            this.pendingMessages.push(message);
        } else {
            this.socket.send(message);
        }
    }

    setConnected(newValue: boolean) {
        if (this.connected === newValue) {
            return;
        }

        this.connected = newValue;
        this.connectionStatus.next(newValue);
    }

    updateSocket() {
        if (this.socket !== null) {
            return;
        }

        console.log('Updating Websocket');

        this.socket = new WebSocket(this.endpoint);
        this.setConnected(false);

        this.socket.onopen = () => {
            console.log('[Websocket] Opened');
            this.setConnected(true);
            this.send('SetClientInstance', this.instanceId);

            for (const data of this.pendingMessages) {
                this.socket?.send(data);
            }
            this.pendingMessages = [];
        };
        this.socket.onclose = (e) => {
            console.log('[Websocket] Closed', e);
            this.setConnected(false);

            if (e.code === 401 || !this.shouldReconnect) {
                this.socket = null;

                return;
            }

            setTimeout(() => {
                this.socket = null;
                this.updateSocket();
            }, 5000);
        };
        this.socket.onmessage = (event) => {
            const data = JSON.parse(event.data);

            this.emitter.next(data);
        };
    }
}

export default WebsocketManager;
